
  import { defineComponent } from 'vue';
  export default defineComponent({
    emits: ['close'],
    methods: {
      hideMenu(event){
        if (!event.currentTarget.contains(event.relatedTarget)) {
          this.$emit('close');
        } 
      },
      focusMenu(){
        (this.$refs.menu as any).focus();
      }
    }
  });
