
import { defineComponent } from 'vue';
import KTTopbar from '@/views/v2/views/layout/Header/UserAvatar.vue';
import Menu from '@/views/v2/views/layout/Header/Menu.vue';
import MainLogo from '@/layout/header/partials/MainLogo.vue';

export default defineComponent({
  name: 'KTHeader',
  props: {
    currentUser: {
      type: Object,
    },
    user: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  watch: {
    $route: {
      handler() {
        this.menu = false;
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    KTTopbar,
    Menu,
    MainLogo,
  },
  methods: {
    goToSettings() {
      this.$router.push('/settings');
    },
    closeMenu(){
      this.menu = false;
    },
    toggleMenu(){
      this.menu = !this.menu;

      if(this.menu){
        this.$nextTick(() => {
          (this.$refs.mainMenu as any).focusMenu();
        });
      }
    }
  }
});
