
import { defineComponent } from 'vue';
import Modal from '@/components/modals/Modal.vue';
import { Form } from 'vee-validate';
import FormField from '@/components/forms/FormField.vue';
import { ResetPassword } from '@/schemas/common.schemas';
import { Actions, Getters } from '@/store/enums/AuthEnums';
import { mapActions, mapGetters } from 'vuex';
import toasts from '@/utils/toasts';
const initialValues = {} as unknown;

export default defineComponent({
    props: {	
        modelValue: { type: Boolean, default: false },
    },
    components: { Modal, Form, FormField },
    data: () => ({
        form: {} as typeof Form,
        formData: initialValues,
        loading: false,
        submitButton: {} as HTMLElement,
        formErrors: {},
    }),
    computed: {
        ...mapGetters({
            errors: Getters.GET_AUTH_USER_ACTION_ERROR
        }),
        dialog: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
        formSchema() {
            return ResetPassword;
        },
    },
    emits: ['update:modelValue'],
    methods: {
        ...mapActions({
            saveNewPassword: Actions.NEW_PASSWORD
        }),
        async save(values){
            const formData = {...values};
            await this.saveNewPassword(formData).then(res => {
                toasts.success('Password successfully created');
                this.dialog = false;
            }).catch(err => {
                const { errors, messages } = this.errors;
                toasts.error(errors, messages);
            });
        }
    },
    mounted() {
        this.form = this.$refs.resetPasswordForm as typeof Form;
        this.submitButton = this.$refs.submitButton as HTMLElement;
    },
});
