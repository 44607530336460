
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import KTAside from '@/layout/aside/Aside.vue';
import KTHeader from '@/views/v2/views/layout/Header.vue';
import KTFooter from '@/views/v2/views/layout/Footer.vue';
import HtmlClass from '@/core/services/LayoutService';
import KTScrollTop from '@/layout/extras/ScrollTop.vue';
import KTUserMenu from '@/layout/header/partials/UserMenu.vue';
import KTLoader from '@/components/Loader.vue';

import { Actions } from '@/store/enums/StoreEnums';
import NewPasswordModal from '@/views/v2/views/components/NewPasswordModal.vue';
import {
  Getters as AuthGetters,
  Mutations as AuthMutations,
} from '@/store/enums/AuthEnums';
import {
  // toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo,
} from '@/core/helpers/config';
import router from '@/router';
import store from '@/store';

export default defineComponent({
  name: 'Layout',
  components: {
    KTAside,
    KTHeader,
    KTFooter,
    KTScrollTop,
    //KTCreateApp,
    KTUserMenu,
    //KTDrawerMessenger,
    KTLoader,
    // KTMobilePageTitle,
    NewPasswordModal,
  },
  data: () => {
    return {
      newPassword: false,
    };
  },
  computed: {
    ...mapGetters({
      actionError: AuthGetters.GET_AUTH_USER_ACTION_ERROR,
      authUser: AuthGetters.GET_AUTH_USER,
    }),

    currentUser() {
      return this.authUser;
    },

    username() {
      if (this.authUser?.first_name && this.authUser?.last_name) {
        return `${this.authUser.first_name} ${this.authUser.last_name}`;
      }

      return `${this.authUser.email}`;
    },

    setRouteIsDashboard() {
      return router.currentRoute.value.name === 'dashboard';
    },
    pageTitle() {
      return store.getters.pageTitle;
    },
    breadcrumbs() {
      return store.getters.pageBreadcrumbPath;
    },
    isDashboard() {
      return this.$router.currentRoute.value;
    },
  },

  async mounted() {
    store.commit(AuthMutations.SET_AUTH_USER_PROFILE_PICTURE, '');

    if (this.authUser.create_new_password) {
      this.newPassword = true;
    }

    setTimeout(() => {
      // Remove page loader after some time
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading');
    }, 500);
  },

  setup() {
    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading');

    // initialize html element classes
    HtmlClass.init();

    return {
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      themeLightLogo,
      themeDarkLogo,
    };
  },
});
