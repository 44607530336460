
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { Actions } from '@/store/enums/AuthEnums';
import Avatar from '@/components/avatars/Avatar.vue';

export default defineComponent({
  name: 'kt-user-menu',
  components: {
    Avatar,
  },
  props: {
    currentUser: {
      type: Object,
    },
    image: {
      type: String,
    },
  },
  computed: {
    userEmail() {
      return this.currentUser?.email ? this.currentUser.email : '';
    },
    userName() {
      if (this.currentUser?.first_name && this.currentUser?.last_name) {
        return `${this.currentUser?.first_name} ${this.currentUser?.last_name}`;
      }

      return this.currentUser?.email;
    },
    userRoles() {
      return this.currentUser?.roles ? this.currentUser.roles[0] : '';
    },
    mailTo() {
      return `mailto:${this.currentUser?.email}`;
    },
  },
  methods: {
    ...mapActions({
      signOut: Actions.LOGOUT,
    }),

    handleLogout() {
      this.signOut().then(() => this.$router.push({ name: 'sign-in' }));
    },
  },
});
