import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "kt_wrapper",
  class: "d-flex flex-column flex-row-fluid wrapper"
}
const _hoisted_2 = {
  id: "kt_content",
  class: "content d-flex flex-column flex-column-fluid p-0"
}
const _hoisted_3 = { class: "d-flex flex-column align-items-center" }
const _hoisted_4 = { class: "content-inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTLoader = _resolveComponent("KTLoader")!
  const _component_KTAside = _resolveComponent("KTAside")!
  const _component_KTHeader = _resolveComponent("KTHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_KTFooter = _resolveComponent("KTFooter")!
  const _component_KTScrollTop = _resolveComponent("KTScrollTop")!
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!
  const _component_NewPasswordModal = _resolveComponent("NewPasswordModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaderEnabled)
      ? (_openBlock(), _createBlock(_component_KTLoader, {
          key: 0,
          logo: _ctx.loaderLogo
        }, null, 8, ["logo"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["page d-flex flex-row flex-column-fluid", { 'bg-main-content': _ctx.isDashboard }])
    }, [
      (_ctx.asideEnabled)
        ? (_openBlock(), _createBlock(_component_KTAside, {
            key: 0,
            lightLogo: _ctx.themeLightLogo,
            darkLogo: _ctx.themeDarkLogo
          }, null, 8, ["lightLogo", "darkLogo"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_KTHeader, {
          title: _ctx.pageTitle,
          user: _ctx.username,
          currentUser: _ctx.currentUser
        }, null, 8, ["title", "user", "currentUser"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_router_view, { style: {"min-height":"calc(100vh - 65px)"} })
            ])
          ])
        ]),
        _createVNode(_component_KTFooter)
      ])
    ], 2),
    _createVNode(_component_KTScrollTop),
    _createVNode(_component_KTUserMenu, { currentUser: _ctx.authUser }, null, 8, ["currentUser"]),
    _createVNode(_component_NewPasswordModal, {
      modelValue: _ctx.newPassword,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newPassword) = $event))
    }, null, 8, ["modelValue"])
  ], 64))
}